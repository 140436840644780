let connection:SignalRClient|null = null;

export function getConnection(url:string){
    console.warn('Use getConnectionAsync. This method can return empty connection')
    if(!connection){
        connection = new SignalRClient(url);
    }
    connection.start()
    return connection;
}

export async function getConnectionAsync(url: string) {
    if (!connection) {
        connection = new SignalRClient(url);
    }

    await connection.start();
    return connection;
}

/** dts bundler can't follow signalR symbols, adding this fake type as a temp workaround */
declare global {
    namespace signalR {
        type HubConnection = any
    }
    var signalR: any
}

export default class SignalRClient {
    private _connection: signalR.HubConnection;
    private _callback: Function|null = null;
    private _callbacks:any={};
    private _lastMessage: string = '';

    constructor(url:string) {

        this._connection = new signalR.HubConnectionBuilder()
            .withUrl(url, {
                transport: signalR.HttpTransportType.WebSockets | 
                        signalR.HttpTransportType.ServerSentEvents | 
                        signalR.HttpTransportType.LongPolling
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();
        
        this._connection.on("OnMessage", (message: any, requestId: any) => {

            let parsedMessage = this.tryParseJSON(message);
            const vKey = requestId

            if(vKey && vKey[0]){
                if(this._callbacks[vKey]){
                    this._callbacks[vKey].call(this,parsedMessage);
                }
            }
            if (this._callback) {
                this._callback(parsedMessage);
            }
        });
        this._connection.onclose(async () => {
            await this.start();
        });

        this._connection.onclose((error?: Error) => {
            if (error) {
                console.error(error);
                this._lastMessage = error.message
            }
        });

    }

    public get connection(): signalR.HubConnection {
        return this._connection;
    }

    public get lastMessage(): string | null {
        return this._lastMessage;
    }

    public async start(): Promise<void> {
        return new Promise((resolve,reject)=>{
            try {
                console.log("In start",this._connection.state)
            if (this._connection.state == signalR.HubConnectionState.Disconnected) { 
                    this._connection.start().then(()=>{
                        console.log("SignalR Connected.");
                        this._lastMessage = "SignalR Connected";
                        resolve();
                    });
                }
            } catch (err: any) {
                console.log(err);
                this._lastMessage = err.message
                reject(err);
            }
        })
       
    }

    private tryParseJSON(jsonString: any): any {
        try {
            if (jsonString && typeof jsonString === "object") {
                return jsonString;
            }
            let parsed = JSON.parse(jsonString);
            if (parsed && typeof parsed === "object") {
                return parsed;
            }
        } catch (e) {
            console.warn("JSON parse error: ", e);
        }
        return false;
    }

    public async send(task:string,vOptions:any,pCallback: (message: any) => void): Promise<void> {
        if (!pCallback) {
            console.warn("Message callback is not registered");
        }
        const vKey = vOptions._requestId;

        if(vKey) {
            this._callbacks[vKey] = pCallback;
        } else {
            this._callback = pCallback;
        }
        
        if (this._connection.state !== signalR.HubConnectionState.Connected) {
            console.log("Awaiting connection");
            await this.start();
            console.log("awaited")
        }
        let msg = JSON.stringify(vOptions);
        // let subject = new signalR.Subject<T>();
        let subject = new signalR.Subject();
         console.log("sending..")
        this._connection.send(task, subject).catch(err => console.error("Send error: ", err));

        let messages = this.chunkString(msg, 1000);
        for (let message of messages) {
            subject.next(message);
        }
        subject.complete();       
    }


    private chunkString(str: string, size: number = 100): string[] {
        let numChunks = Math.ceil(str.length / size);
        let chunks = new Array<string>(numChunks);

        for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
            chunks[i] = str.substring(o, o + size);
        }

        return chunks;
    }

    public stopConnection(): Promise<void> {
        this._callback = null;
        this._callbacks = {};
        return this._connection.stop();
    }

    async reconnect() {
        this._callback = null;
        this._callbacks = {};
        const onClose = this._connection.onclose;
        this._connection.onclose = undefined;
        await this._connection.stop();
        await this.start();
        this._connection.onclose = onClose;
        
        return this._connection;
    }
}